<template>
  <div class="">
    <!-- <b-card class="mb-1">
      <h2>
        Modifier les informations de mon établissement
      </h2>
      </b-card> -->
    <b-card class="mb-0">
      <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->

      <!-- form -->
      <b-form
        id="editEtsForm"
        class="auth-register-form mt-2"
        @submit.prevent="submitForm"
      >
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- ifu -->
            <b-form-group
              label="IFU (Facultatif)"
              label-for="ifu"
            >
              <b-form-input
                id="ifu"
                v-model="form.ifu"
                name="ifu"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- rccm -->
            <b-form-group
              label="RCCM (Facultatif)"
              label-for="rccm"
            >
              <b-form-input
                id="rccm"
                v-model="form.rccm"
                name="rccm"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- agrement -->
            <b-form-group
              label="Numero Agrement (Facultatif)"
              label-for="numero_agrement"
            >
              <b-form-input
                id="numero_agrement"
                v-model="form.numero_agrement"
                name="numero_agrement"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- nom ets -->
            <b-form-group
              label="Nom de l'etablissement (Obligatoire)"
              label-for="nomEts"
            >
              <b-form-input
                id="nomEts"
                v-model="form.nom_ets"
                :state="$v.form.nom_ets.$error ? false : null"
                name="nomEts"
              />
              <div v-if="$v.form.nom_ets.$error">
                <small
                  v-if="$v.form.nom_ets.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>

        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- type_ets -->
            <b-form-group
              label="Type d'établissement (Obligatoire)"
              label-for="type_ets"
            >
              <v-select
                v-model="form.type_ets"
                type="text"
                :options="typesEts"
                label="nom"
                required="true"
                class="form-input"
              />
              <div v-if="$v.form.type_ets.$error">
                <small
                  v-if="$v.form.type_ets.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- adresse_Ets -->
            <b-form-group
              label="Adresse de l'etablissement (Obligatoire)"
              label-for="adresse_Ets"
            >
              <b-form-input
                id="adresse_Ets"
                v-model="form.adresse_ets"
                :state="$v.form.adresse_ets.$error ? false : null"
                name="adresse_Ets"
              />
              <div v-if="$v.form.adresse_ets.$error">
                <small
                  v-if="$v.form.adresse_ets.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>
        <!-- telephone_ets -->
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <b-form-group
              label="Telephone de l'etablissement (Obligatoire)"
              label-for="telephone_ets"
            >
              <b-form-input
                id="telephone_ets"
                v-model="form.telephone_ets"
                v-phonenumber-field

                :state="$v.form.telephone_ets.$error ? false : null"
                name="telephone_ets"
              />
              <div v-if="$v.form.telephone_ets.$error">
                <small
                  v-if="$v.form.telephone_ets.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- email_ets -->
            <b-form-group
              label="Email de l'etablissement (Obligatoire)"
              label-for="email_ets"
            >
              <b-form-input
                id="email_ets"
                v-model="form.email_ets"
                :state="$v.form.email_ets.$error ? false : null"
                name="email_ets"
              />
              <div v-if="$v.form.email_ets.$error">
                <small
                  v-if="$v.form.email_ets.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
                <small
                  v-if="$v.form.email_ets.email.$invalid"
                  class="text-danger"
                >Adresse email invalid</small>
              </div>
            </b-form-group>
          </b-col></b-row>

        <hr class="my-2">
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- nom_promoteur -->
            <b-form-group
              label="Nom du promoteur de l'etablissement (Obligatoire)"
              label-for="nom_promoteur"
            >
              <b-form-input
                id="nom_promoteur"
                v-model="form.nom_promoteur"
                :state="$v.form.nom_promoteur.$error ? false : null"
                name="nom_promoteur"
              />
              <div v-if="$v.form.nom_promoteur.$error">
                <small
                  v-if="$v.form.nom_promoteur.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- telephone_promoteur -->
            <b-form-group
              label="Telephone du promoteur de l'etablissement (Obligatoire)"
              label-for="telephone_promoteur"
            >
              <b-form-input
                id="telephone_promoteur"
                v-model="form.telephone_promoteur"
                v-phonenumber-field

                :state="$v.form.telephone_promoteur.$error ? false : null"
                name="telephone_promoteur"
              />
              <div v-if="$v.form.telephone_promoteur.$error">
                <small
                  v-if="$v.form.telephone_promoteur.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- email_promoteur -->
            <b-form-group
              label="Email du promoteur de l'etablissement (Facultatif)"
              label-for="email_promoteur"
            >
              <b-form-input
                id="email_promoteur"
                v-model="form.email_promoteur"
                name="email_promoteur"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- cpi_npi_promoteur -->
            <b-form-group
              label="CPI/NPI du promoteur de l'etablissement (Obligatoire)"
              label-for="cpi_npi_promoteur"
            >
              <b-form-input
                id="npi_promoteur"
                v-model="form.cpi_npi_promoteur"
                :state="$v.form.cpi_npi_promoteur.$error ? false : null"
                name="npi_promoteur"
              />
              <div v-if="$v.form.cpi_npi_promoteur.$error">
                <small
                  v-if="$v.form.cpi_npi_promoteur.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- departement -->
            <b-form-group
              label="Departement (Obligatoire)"
              label-for="departement"
            >
              <v-select
                v-model="form.departement"
                type="text"
                :options="departements"
                label="name"
                required="true"
                class="form-input"
              />
              <div v-if="$v.form.departement.$error">
                <small
                  v-if="$v.form.departement.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- communes -->
            <b-form-group
              label="Communes (Obligatoire)"
              label-for="communes"
            >
              <v-select
                v-model="form.commune"
                type="text"
                :options="communes"
                label="name"
                required="true"
                class="form-input"
              />
              <div v-if="$v.form.departement.$error">
                <small
                  v-if="$v.form.departement.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>
        <b-row>
          <b-col
            md="12"
            lg="6"
          >
            <!-- arrondissements -->
            <b-form-group
              label="Arrondissements (Obligatoire)"
              label-for="arrondissements"
            >
              <v-select
                v-model="form.arrondissement"
                type="text"
                :options="arrondissements"
                label="name"
                required="true"
                class="form-input"
              />
              <div v-if="$v.form.arrondissement.$error">
                <small
                  v-if="$v.form.arrondissement.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="6"
          >
            <!-- qtier -->
            <b-form-group
              label="Quartier/village (Obligatoire)"
              label-for="qtier"
            >
              <v-select
                v-model="form.qtier"
                type="text"
                :options="quartiers"
                label="name"
                required="true"
                class="form-input"
              />
              <div v-if="$v.form.qtier.$error">
                <small
                  v-if="$v.form.qtier.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col></b-row>
        <!-- pieces_jointe -->
        <div class="d-flex flex-wrap gap-2">
          <div
            v-for="(file, i) in existingFormFiles"
            :key="'file' + i"
            class="border border-primary ets-files-details-item"
          >
            {{ getCloudinaryFileName(file) }}
            <i class="ri-delete-bin-fill" />
          </div>
        </div>
        <b-form-group
          label="Pieces jointes (Obligatoire) (5 fichiers au maximum)"
          label-for="pieces_jointe"
        >
          <!-- Styled -->
          <b-form-file
            v-model="form.pieces_jointes"
            multiple
            :state="$v.form.pieces_jointes.$error ? false : null"
            placeholder="Choissisez un fichier ou glisser déposer ici..."
            drop-placeholder="Déposer ici..."
          />
          <div v-if="$v.form.pieces_jointes.$error">
            <small
              v-if="$v.form.pieces_jointes.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>
        <!-- recaptcha -->
        <div
          v-if="enableEditing"
          class="mb-1"
        >
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
          >Veuillez resuodre le recaptcha pour continuer</small>
        </div>

        <!-- submit button -->
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="enableEditing = !enableEditing"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              {{ enableEditing ? "Réinitialiser le formulaire" : "Modifier" }}
            </b-button>
          </b-col>
          <b-col v-if="enableEditing">
            <b-button
              variant="primary"
              block
              type="submit"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              Enregistrer les modifications
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) }
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
  },
  mixins: [togglePasswordVisibility],
  props: {
    inputsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      enableEditing: false,
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      form: {},
      typesEts: [],
      passwordFieldType: 'password',

      recaptchaResponse: null,

      isLoading: false,
      existingFormFiles: [],
      deletedFormFiles: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    inputsData: {
      immediate: true,
      handler(val, old) {
        const createNameObj = val => ({ name: val })
        this.form = {
          nom_ets: val.nomEtablissement,
          type_ets: val.typeEtablissement,
          adresse_ets: val.adresseEtablissement,
          telephone_ets: val.telephoneEtablissement,
          email_ets: val.emailEtablissement,
          nom_promoteur: val.nomPromoteur,
          telephone_promoteur: val.telephonePromoteur,
          email_promoteur: val.emailPromoteur,
          cpi_npi_promoteur: val.npicniPromoteur,
          departement: createNameObj(val.departement),
          commune: createNameObj(val.commune),
          arrondissement: createNameObj(val.arrondissement),
          qtier: createNameObj(val.quartier),
          pieces_jointes: [],
          rccm: val.rccm || '',
          ifu: val.ifu || '',
          numero_agrement: val.referenceAgrement || '',
        }
        this.existingFormFiles = Array.isArray(val.fichiers)
          ? [...val.fichiers]
          : []
      },
    },
    enableEditing: {
      handler(val, old) {
        if (val === true) {
          this.enableInputs()
        } else {
          this.disableInputs()
        }
      },
    },
    'form.departement': {
      handler(val, old) {
        (this.form.commune = ''), (this.communes = [])
        this.loadCommunes()
      },
    },
    'form.commune': {
      handler(val, old) {
        (this.form.arrondissement = ''), (this.arrondissements = [])
        this.loadArrondissements()
      },
    },
    'form.arrondissement': {
      handler(val, old) {
        (this.form.qtier = ''), (this.quartiers = [])
        this.loadNeighborhoods()
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  validations() {
    return {
      form: {
        nom_ets: { required },
        type_ets: { required },
        adresse_ets: { required },
        telephone_ets: { required },
        email_ets: { required, email },
        nom_promoteur: { required },
        telephone_promoteur: { required },
        cpi_npi_promoteur: { required },
        departement: { required },
        commune: { required },
        arrondissement: { required },
        qtier: { required },
        pieces_jointes: { required },
        // rccm: { required },
        // ifu: { required },
        // numero_agrement: { required },
        // password: { required },
        // confirmPassword: {
        //   isValid: val => val && val === this.form.password,
        // },
      },

      recaptchaResponse: {
        isValid: val => val && val.type === 'success',
      },
    }
  },
  mounted() {
    this.disableInputs()
    this.getListTypesEtablissement()
    this.loadDepartments()
    this.loadCommunes()
    this.loadArrondissements()
    this.loadNeighborhoods()
  },
  methods: {
    ...mapActions('params', {
      action_loadDepartments: 'loadDepartments',
      action_loadCommunes: 'loadCommunes',
      action_loadArrondissements: 'loadArrondissements',
      action_loadNeighborhoods: 'loadNeighborhoods',
    }),
    ...mapActions('etablissements', {
      updateEtablissement: 'updateEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    getCloudinaryFileName(file) {
      return `${file.original_filename}.${file.format}`
    },
    enableInputs() {
      if (document) {
        document
          .querySelectorAll('#editEtsForm .form-group')
          .forEach(inputItem => {
            // eslint-disable-next-line no-param-reassign
            inputItem.style.pointerEvents = 'auto'

            const inputEl = inputItem.querySelector('input, .form-input')
            inputEl.removeAttribute('disabled')
            inputEl.classList.remove('disabled-input')
          })
      }
    },
    disableInputs() {
      if (document) {
        document
          .querySelectorAll('#editEtsForm .form-group')
          .forEach(inputItem => {
            inputItem.style.pointerEvents = 'none'

            const inputEl = inputItem.querySelector('input, .form-input')
            inputEl.setAttribute('disabled', 'disabled')
            inputEl.classList.add('disabled-input')
          })
      }
    },
    loadDepartments() {
      this.action_loadDepartments()
        .then(res => {
          this.departements = res
        })
        .catch(err => {})
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then(res => {
          console.log('typeets', res)
          this.typesEts = res.data.typeetablissements || []
        })
        .catch(err => {})
    },
    loadCommunes() {
      this.action_loadCommunes(this.form.departement)
        .then(res => {
          this.communes = res
        })
        .catch(err => {})
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.form.commune)
        .then(res => {
          this.arrondissements = res
        })
        .catch(err => {})
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.form.arrondissement)
        .then(res => {
          this.quartiers = res
        })
        .catch(err => {})
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    async submitForm() {
      const isFormCorrect = await this.$v.$validate()
      if (this.isLoading) return
      if (!isFormCorrect) return
      this.isLoading = true
      const files = this.form.pieces_jointes
      const formData = new FormData()
      const objectdata = {
        nomEtablissement: this.form.nom_ets,
        typeEtablissement: this.form.type_ets.id,
        adresseEtablissement: this.form.adresse_ets,
        telephoneEtablissement: this.form.telephone_ets,
        emailEtablissement: this.form.email_ets,
        password: this.form.password,
        nomPromoteur: this.form.nom_promoteur,
        telephonePromoteur: this.form.telephone_promoteur,
        npicniPromoteur: this.form.cpi_npi_promoteur,
        departement: this.form.departement.name,
        commune: this.form.commune.name,
        arrondissement: this.form.arrondissement.name,
        quartier: this.form.qtier.name,
        referenceAgrement: this.form.numero_agrement,
        rccm: this.form.rccm,
        ifu: this.form.ifu,
      }
      console.log(objectdata, this.form)
      const fileprop = 'fichiers'
      const filenumber = files.length || 0

      formData.append('objectdata', JSON.stringify(objectdata))
      // formData.append("fileprop", fileprop);
      // formData.append("filenumber", `${filenumber}`);

      // for (let index = 0; index < files.length; index++) {
      //   const file = files[index];
      //   formData.append(`filedata${index}`, file);
      // }
      this.updateEtablissement({ id: this.inputsData._id, data: formData })
        .then(result => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Mise à jour avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (localstorageService.getUserId()) {
            this.$router.replace({ name: 'etablissements-list' })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Parcourir";
}
</style>
